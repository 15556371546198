import IDashboardComponent from "./IDashboardComponent";
import {TDashboardData} from "../model/TDashboardData";

export default class FeedbackOverview implements IDashboardComponent {

    constructor(
        private element: HTMLElement
    ) {
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData): void {
        const data: TFeedbackData = state["feedback.overview"].data as TFeedbackData;
        const htmlParts = [];

        // stars
        for (let i = 0; i < data.stars?.length ?? 0; i++) {
             htmlParts.push(this.generateStars(data.stars[i]));
        }

        // checkbox
        const checkboxHtmlParts = [];
        for (let i = 0; i < data.checkbox?.length ?? 0; i++) {
            checkboxHtmlParts.push(this.generateCheckbox(data.checkbox[i]));
        }

        htmlParts.push(`<div class="checkboxes">${checkboxHtmlParts.join('')}</div>`);

        this.element.innerHTML = '<div class="parts-container">' + htmlParts.join('') + '</div>';
    }

    private generateStars(starsData: TData): string {
        const dataObjectsCount = starsData.data?.length ?? 0;
        let sum = 0;
        let count = 0;
        let index = 1;

        for (let value of starsData.data) {
            count += value.count;
            sum += index * value.count;
            index++;
        }

        const avg = count > 0 ? sum / count : 0;
        const avgRounded = Math.round(avg * 100) / 100; // round to 2 decimal places
        const starsHtmlEls = Array(dataObjectsCount).fill('<span class="star icon ico-star"></span>').join('');

        return `<div class="rating-stars">
            <div class="stars">${starsHtmlEls}</div>
            <div class="avg-rating">${avgRounded} / ${dataObjectsCount}</div>
            <div class="count">n = ${count}</div>
            <div class="label">${starsData.label}</div>
        </div>`;
    }

    private generateCheckbox(checkboxData: TData) {
        let htmlParts = [];
        let sum = 0;

        for (let i = 0; i < checkboxData.data?.length ?? 0; i++) {
            const valueData = checkboxData.data[i];
            sum += i * valueData.count;

            htmlParts.push(`<div class="value">
                <span class="count">${valueData.count}</span>
                <span class="label">${valueData.label}</span>                
            </div>`);
        }

        let ratioString = '0';

        if (checkboxData.count > 0) {
            const ratio = (1 - sum / checkboxData.count) * 100;
            const ratioRounded = Math.round(ratio * 10) / 10;
            ratioString = `${ratioRounded} %`;
        }


        return `<div class="checkbox">
            <div class="label">${checkboxData.label}</div>
            <span class="ratio">${ratioString}</span>
            <div class="values">${htmlParts.join('')}</div>
        </div>`;
    }

}

type TFeedbackData = {
    stars: TData[];
    choice: TData[];
    checkbox: TData[];
}

type TData = {
    label: string;
    count: number;
    value: null;
    data: null | TData[];
}